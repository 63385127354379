<template>
  <div>
     <v-card-title  class="BoldBuilderGradient justify-start mediumoverline">
          Documentation Libraries <v-spacer>
              </v-spacer><v-btn @click="CheckBack()">Back</v-btn>
        </v-card-title>
    <v-card-text >
          <QuickEditComponent v-if="ActiveLibrary" :System="System" :SystemEntities="SystemEntities" :Record="SystemInstance" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect" @SelectImage="SelectImage"
            @AddGallerySubcolImages="AddGallerySubcolImages"
            />
            <v-list>
                <v-list-item v-if="!DocumentationLibraryActive" >
                    <v-list-item-content class="subtleoverline">
                        Documentation Library Plugin not Installed
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn outlined @click="PickPlugin(SelectedPlugin)">Activate
                    </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-for="doclib in DocumentationLibraries" :key="doclib.itemObjKey" @click="ActivateDocumentLibrary(doclib)">
                    <v-list-item-avatar>
                        <v-icon>{{doclib.Icon}}
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        {{doclib.Name}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
      </v-card-text>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import QuickEditComponent from '@/components/Database/QuickEditComponent'

export default {
    props: ['System','SystemEntities','ActiveTemplate','DocumentationLibraries'],
    components: {QuickEditComponent},
    data() {
        return {
            ActiveLibrary: '',
            TemplateCategoryFixes: [
                {Fieldid: 'Group_Categories',ArrayName: 'GroupsCategories'},
                {Fieldid: 'Forum_Categories',ArrayName: 'ForumsCategories'}
            ],
            GroupsCategories: [],
            ForumsCategories: [],
            SystemTickets: [],
            RoadmapItems: [],
            SystemInstance: '',
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	

        }
    },	
    computed:{
        DocumentationLibraryActive(){
            return this.PluginDataBase && this.PluginDataBase.Documentation_Builder && this.PluginDataBase.Documentation_Builder.Active
        },
        SelectedPlugin(){
            return this.RAPlugins.find(obj => obj.id === 'Documentation_Builder')
        },
        RAPlugins(){
            return this.$store.state.RAPlugins
        },
        CurrentEntityFields(){
            //console.log('SystemConfigEntity',this.SystemConfigEntity)
            return this.SystemConfigEntity.AllFields.map(field => {
                let fieldobj = Object.assign({},field)
                let match = this.TemplateCategoryFixes.find(obj => obj.Fieldid === field.id)
                if(this.ActiveTemplate && match){
                    if(!this.ActiveTemplate.CategoryFields){
                        this.ActiveTemplate.CategoryFields = []
                    }
                    let tempfield = this.ActiveTemplate.CategoryFields.find(obj => obj.id === fieldobj.id)
                    if(tempfield){
                        fieldobj = tempfield
                        fieldobj.Options = this[match.ArrayName]
                    }
                    else{
                        fieldobj.lastassignedinteger = 1000000
                        fieldobj.Options = this[match.ArrayName]
                    }
                }
                //then only "missing fix" being when we store, we should store these categories in a subcol, why stuff around right?
                //So these values assign ConfigPath through UpdateQuickEdits function
                return fieldobj
            })
            // we canot obejct assign or else things like lastassignedinterger on dyn options et wn't work, keep reassigning to previous
            //what we will do it call the field value, so i changes are canclled, reset to stored value of system, or systme entity alike. yeah..I think. yes 
            //okay sure so we modify state, but reassign abck to system props, and like when we alter dynamic option sets, we alter the field..should be okay?
            //.map(field => {
            //     let fieldobj = Object.assign({},field)
            //     return fieldobj
            // })
        },
        SystemConfigEntity(){
            return this.$store.state.SystemConfigEntity
        },
        PluginDataBase(){
            return this.ActiveTemplate ? this.ActiveTemplate.PluginDataBase : this.$store.state.PluginDataBase
        },
      QuickEditSections(){
            //we have Guest_Default_Page and Guests_Default_Page have to decide
        //   let sect1fields = this.ActiveTemplate ? ['Social_Module_Name'] : ['Social_Landing_Page','Social_Module_Name']
        //   let sect2fields = ['Guests_can_Social','Guest_Landing_Page','Social_Content_for_Guests','Disable_Guest_Posts']
        //   let sect3fields = ['Site_Articles_Public','Site_Blogs_Public','Site_Events_Public','Site_Meetups_Public']
        //   let sect4fields = ['Forum_Categories']
           //let sect6fields = ['Group_Categories']
            //this directories need to atually start looking if any is active but yeah
            let quicklists = this.DocumentationLibraries.map(doclib => {
                return {Name: doclib.Name,Pluginid: 'Documentation_Library',Path: '/DocumentationBuilder/'+doclib.id}
            })
            // .filter(item => {
            //         return this.PluginDataBase[item.Pluginid] && this.PluginDataBase[item.Pluginid].Active
            //     })
            if(this.ActiveTemplate){
                quicklists = quicklists.map(item => {
                    item.Path = this.ActiveSuiteTemplate  ? '/Your-Domain/'+this.ActiveTemplate.id+item.Path : '/MarketplaceTemplate/'+this.ActiveTemplate.id+item.Path
                    return item
                })
            }
                //need quicklists but everything for that matter must check plugins. and groups here, is not site groups plugin but group direcotries plugin
          return [
           {Header: 'Directories',Type: 'QuickList',QuickLists: quicklists,Class: 'flex xl12 lg12 md12 sm12 xs12'},
          //{Header: 'Group Categories',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect6fields),Class: 'flex xl12 lg12 md12 sm12 xs12'},
          ]
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      System: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id && !this.ActiveTemplate){
                     this.SystemInstance = this.System
                }
            },deep: true
        },
    },
    created(){
        if(this.ActiveTemplate){
            this.SystemInstance = this.ActiveSuiteTemplate ? this.ActiveTemplate : this.ActiveTemplate.TemplateProps
            //This may become the new standard entire suite level cause updating system props is triggering too many things downstream.
            this.GetSiteForumsCategories()
            let tempmatch = this.MarketplaceTemplates.find(obj => obj.id === this.ActiveTemplate.id)
           // console.log('this.ActiveTemplate.CategoryFields',this.ActiveTemplate.CategoryFields,this.MarketplaceTemplates,'tempmatch',tempmatch)
            
        //console.log('this.ActiveTemplate.Assets',this.ActiveTemplate.Assets)
        }
        else{
            this.SystemInstance = this.System
        }
    },
    methods:{
        PickPlugin(SelectedPlugin){
            //console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        // let raplugins = this.$store.state.RAPluginPackages
        // let pluginpackages = raplugins.filter(pack => {
        //     let packplugs = pack.Plugins.map(plug => {
        //         return plug.id
        //     })
        //     return packplugs.includes(SelectedPlugin.id)
        // })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          let pluginpackages = [pluginpack]
          //console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
        },
        ActivateDocumentLibrary(doclib){
            this.$store.commit('setBuilderView',{Name: 'Documentation Builder'})
             let path = '/DocumentationLibraryEditor/'+doclib.id+'/'+doclib.FirstPageName.split(' ').join('-')
             if(this.$route.path !== path){
                this.$router.push(path)
            }
            // let RAApp = firebase.apps.find(obj => obj.name === 'RapidApps')
            // let RADB = RAApp.firestore()
            // let raref = RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries').doc(doclib.id)
            // let clientref = db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('DocumentationLibraries').doc(doclib.id)
            // raref.get().then(snapshot => {
            //     let docdata = snapshot.data()
            //     clientref.set(docdata).then(newlib => {
            //         raref.collection('Pages').onSnapshot(res => {
            //                 const changes = res.docChanges();
            //                 changes.forEach((change,i) => {
            //                     if (change.type === 'added') {
            //                     let libpageobj = {
            //                         ...change.doc.data(),
            //                             id: change.doc.id
            //                     }
            //                     clientref.collection('Pages').doc(libpageobj.id).set(libpageobj)
            //                     }
            //                 })
            //             })
            //     })
            // })
        },
        CheckBack(){
            if(this.ActiveLibrary){
                this.ActiveLibrary = ''
            }
            else{
                this.$emit('GoBack')
            }
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = this.CurrentEntityFields.find(obj => obj.id === field)
            //console.log(field,fieldobj,this.System)
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.SystemInstance[fieldobj.Name] !== 'undefined' && typeof this.SystemInstance[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.SystemInstance[fieldobj.Name].toDate()
                this.SystemInstance[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            if(fieldobj.Type === 'Calculated Field'){
              this.SystemInstance[fieldobj.Name] = this.PerformedCalculation(this.SystemInstance,fieldobj)
            }
            if(fieldobj.Type === 'Option Set' && fieldobj.IsDynamic && typeof this.SystemInstance[fieldobj.Name] !== 'undefined'){
                //console.log(this.System[fieldobj.Name])
                fieldobj = JSON.parse(JSON.stringify(this.SystemInstance[fieldobj.Name]))
            }
            return fieldobj
          })
      },
    }
}
</script>

<style>

</style>



